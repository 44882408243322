import {ChColumn, ChInput, Loader, Avatar, ChBadge} from "ch3-ui-lib";
import {useSelector} from "react-redux";
import {getFlag, getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import {FlagImage} from "react-international-phone";
import {Link} from "react-router-dom";
import {useCallback, useState} from "react";
import {debounce} from "lodash";
import noGroupsIcon from "../../assets/empty.svg";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import { borderStyles, boxStyles } from "./helpers.tsx";
import {
    useFetchMsisdnsAdminQuery,
    useFetchMsisdnsQuery
} from "../../store/features/MsisdnSettings/msisdnSettingsService";
import {selectUserId} from "../../store/features/Auth/authSlice";


interface PhoneNumbersProps {
    admin?: boolean;
}
export const PhoneNumbers = ({admin = false}: PhoneNumbersProps) => {
    const theme = useSelector((state:any) => state.themeSwitcher.theme);
    const userId = useSelector(selectUserId);

    const [phrase, setPhrase] = useState<string>('');



    const isMobile = useMediaQuery('(max-width: 960px)');

    const { data: msisdns, isLoading } = admin
        ? useFetchMsisdnsAdminQuery({ phrase })
        : useFetchMsisdnsQuery({ phrase, userId })

    const handlePhraseChange = useCallback(
        debounce((phrase: string) => {
            setPhrase(phrase);
        }, 1000), // 1 second delay
        []
    );
   const handleDataChange = (data: any) => {
        console.log(data);
    }

    if (isLoading) {
        return (
            <div className={`flex w-full `}>
                <Loader/>
            </div>


        );
    }
    return( msisdns &&
        <div className='w-full'>
            <div className={`${isMobile ? ' px-4' : ' '}`}>
                <div className='mx-8 flex justify-end m-3 h-[36px]'>
           <ChInput placeholder={'Search'} className={`${isMobile ? ' ' : ' min-w-[375px]'}`} prefixIcon={'search'} callback={(value: string) => handlePhraseChange(value)} />
                </div>

                <div className={` overflow-y-auto`}>
                    <div className={clsxMerge(boxStyles({theme}))}>

                    <table className={`w-full border-y ` + clsxMerge(borderStyles({theme}))}>
                        <thead>
                        <tr className={`border-b ` + clsxMerge(borderStyles({theme}))} >
                            <th className='pl-4 py-3 w-full' onClick={() => handleDataChange([])}
                            ><ChColumn propertyName='name' label={'Name'} /></th>
                        </tr>
                        </thead>
                        <tbody>
                        {msisdns.data.map((msisdn) => (
                            <tr key={msisdn.msisdnId}>

                                    <td className='pl-4 py-3 w-full cursor-pointer'>
                                        <Link to={ (admin ? '/admin' : '' ) +  `/phoneNumbers/${msisdn.msisdnId}`}>
                                        <div className='flex justify-between'>
                                            <div className={'flex gap-3'}>
                                                <div className='text-2xl items-end flex'>
                                                    <Avatar icon={msisdn.icon ? msisdn.icon : 'home'} size="sm" type='icon' color={msisdn.iconColor? msisdn.iconColor : 'blue'}
                                                            name=''/>
                                                </div>
                                                <div>
                                                    <div className='text-sm'>
                                                        {msisdn.label ?? 'No name'}
                                                    </div>
                                                    <div className='flex items-center text-xs'>
                                                        <FlagImage iso2={getFlag(msisdn.msisdn)} size={16} />
                                                        <div className={'ml-2'}>
                                                            {getFormatedPhoneNumber(msisdn.msisdn, true)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='mr-4' >
                                                {msisdn?.assigmentScope == 'PRIVATE' && <ChBadge className={'px-2'} label={msisdn?.assigmentScope?.toLowerCase()}
                                                          badgeColor={'grey'}/>}
                                            </div>
                                        </div>
                                        </Link>
                                    </td>
                            </tr>
                        ))}
                        {msisdns.data.length === 0 && phrase &&
                            <div className='flex text-center  justify-center'>
                                <div className='w-[450px]  m-14'>
                                    <div className='flex  justify-center w-full'>
                                        <img className='m-4' src={noGroupsIcon}/>
                                    </div>

                                    <h1 className='font-bold'>
                                        No phone numbers found
                                    </h1>
                                    <div className='mb-6 mt-2'>
                                        No phone numbers found matching your search. Please double-check your criteria.
                                    </div>

                                </div>

                            </div>

                        }
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>

    )
}
