import {hexToEmoji} from "../Emoji/emoji";
import { borderStyles} from "./helpers.tsx";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import {Avatar, Button} from "ch3-ui-lib";
import {Link} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {useSelector} from "react-redux";
import {useEffect} from "react";

interface HeaderRouteConfig {
    label?: string;
    icon?: string;
    buttonLabel?: string;
    buttonPath?: string;
    backButtonLabel?: string;
    backButtonPath?: string;
    avatar?: boolean;
    description?: string;
}

const headerConfig: Record<string, HeaderRouteConfig> = {
    "/": { label: "Calls", icon: "1f919" },
    "/calls": { label: "Calls", icon: "1f919" },
    "/call/:number": { label: "Calls", icon: "1f919" },
    "/contacts": { label: "Contacts", icon: "1f4d2", buttonLabel: "Create new", buttonPath: "/contacts/new" },
    "/contacts/:id": { label: "Edit Contact", icon: "1f4d2", backButtonLabel: "Back to Contacts", backButtonPath: "/contacts" },
    "/contacts/new": { label: "New Contact", backButtonLabel: "Back to Contacts", backButtonPath: "/contacts" },
    "/contacts/details/:id": { backButtonLabel: "Back to Contacts", backButtonPath: "/contacts" },
    "/team": { label: "Team", icon: "1F465" },
    "/groups": { label: "Groups", icon: "1F46A" },
    "/groups/details/:id": { backButtonLabel: "Back to Groups", backButtonPath: "/groups" },
    "/groups/details/new": { label: "New Group",  backButtonLabel: "Back to Groups", backButtonPath: "/groups" },
    "/phoneNumbers": { label: "Phone Numbers", icon: "1F4F1" },
    "/phoneNumbers/:id": { backButtonLabel: "Back to the phone numbers", backButtonPath:"/phoneNumbers" },
    "/profile": { label: "You", avatar: true },
    "/admin/integrations": { label: "Integrations", description: "Manage all connected apps and integrations to streamline team operations." },
    "/admin/report": { label: "Reports" },
    "/admin/callQueues": { label: "Calls queue", buttonLabel: "Create new queue", buttonPath: "/admin/callQueues/new" },
    "/admin/callQueues/new": { label: "New call queue", backButtonLabel: "Back to Calls queue", backButtonPath: "/admin/callQueues"},
    "/admin/callQueues/:id": { label: "Edit", backButtonLabel: "Back to Calls queue", backButtonPath: "/admin/callQueues" },
    "/admin/phoneNumbers": { label: "Phone Numbers", icon: "1F4F1" },
    "/admin/phoneNumbers/:id": { backButtonLabel: "Back to the phone numbers", backButtonPath:"/admin/phoneNumbers" },
    "/admin/groups": { label: "Groups", icon: "1F46A", buttonLabel: "Create new group", buttonPath: "/admin/groups/details/new" },
    "/admin/groups/details/:id": { backButtonLabel: "Back to Groups", backButtonPath: "/admin/groups" },
    "/admin/groups/details/new": { label: "New Group",  backButtonLabel: "Back to Groups", backButtonPath: "/admin/groups" },
    "/admin/integrations/google": { label: "Integrations", description: "Manage all connected apps and integrations to streamline team operations." },
    "/admin/integrations/google/connect": { label: "Integrations", description: "Manage all connected apps and integrations to streamline team operations." },
    "/admin/integrations/google/list": { label: "Integrations", description: "Manage all connected apps and integrations to streamline team operations." },
};

const matchRoute = (pathname: string) => {
    if (headerConfig[pathname]) {
        return headerConfig[pathname];
    }

    // Convert dynamic routes (with ":param") into regex
    const matchingRoute = Object.keys(headerConfig)
        .filter(route => route.includes(":"))
        .map(route => ({
            route,
            regex: new RegExp("^" + route.replace(/:\w+/g, "[^/]+") + "$"),
        }))
        .find(({ regex }) => regex.test(pathname));

    return matchingRoute ? headerConfig[matchingRoute.route] : null;
};

const Header = () => {
    const location = useLocation();
    const { pathname } = location;

    const currentRoute = matchRoute(pathname);

    const theme = useSelector((state: any) => state.themeSwitcher.theme);

    const isMobile = useSelector((state: any) => state.isMobile);
    useEffect(() => {
        const header = document.getElementById('header');
        if (header) {
            const headerHeight = header.offsetHeight;
            document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
        }
    }, [pathname]);
    if (!currentRoute) {
        return null;
    }

    return (
        <div id="header" className={`sticky top-0  overflow-x-hidden ` + clsxMerge(borderStyles({ theme })) + `${isMobile ? ' ml-[80px]' : ' ml-[272px] '}`}>
            {currentRoute?.backButtonLabel && <div className={`py-5 px-4 border-b ` + clsxMerge(borderStyles({theme}))}>
                {currentRoute.backButtonLabel && currentRoute.backButtonPath && (
                    <Link className='flex items-center' to={currentRoute.backButtonPath}>
                        <Button size='small' className='pl-1' buttonType='textPrimary' rightIcon='arrow_back_ios'/>
                        <div className="text-primary-400 font-semibold">
                            {currentRoute.backButtonLabel}
                        </div>
                    </Link>
                )}
            </div>}
            {currentRoute.label && <div className={`flex justify-between z-10 relative border-b border-black flex-wrap p-8`}>
                <div className='flex items-center gap-3'>
                    {currentRoute.avatar && <Avatar size="xl" name="User Name"/>}
                    {currentRoute.icon &&
                        <div className="text-[32px]">{hexToEmoji(currentRoute.icon, 'w-[32px] h-[32px] mr-4')}</div>}
                    <div>
                        <h5 className='font-black '>{currentRoute.label}</h5>
                        <div>
                            {currentRoute.description && (
                                <p className={`${isMobile ? ' flex-wrap ' : ' no-wrap '}`}>
                                    {currentRoute.description}
                                </p>
                            )}
                        </div>
                    </div>


                </div>
                <div className='flex items-center '>
                    {currentRoute.buttonLabel && (
                        <Link to={`${currentRoute.buttonPath}`}>
                            <Button label={currentRoute.buttonLabel} size='small' buttonType='primary'>

                            </Button>
                        </Link>
                    )}
                </div>
            </div>}

        </div>
    );
};

export default Header;
