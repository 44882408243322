import {Avatar, Button, ChInput, ChModal, Loader} from "ch3-ui-lib";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import AddUserNumberModal from "./AddUserNumberModal";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {validateName} from "../../utilis/commonValidators";
import {useNavigate} from 'react-router-dom';
import DeleteGroupModal from "./DeleteGroup";
import {Colors, selectorItem} from "../../utilis/types/Types";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {showToast} from "../../store/features/Notifications/notificationsSlice";
import { borderStyles } from "./helpers.tsx";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import {
    Group,
    useAddGroupMutation, useFetchAdminGroupByIdQuery,
    useFetchGroupByIdQuery,
    useUpdateGroupMutation
} from "../../store/features/groups/groupsService";
import {useFetchUsersQuery, User} from "../../store/features/Users/userService";
import {Msisdn} from "../../store/features/Msisdns/msisdnService";
import {selectUserId} from "../../store/features/Auth/authSlice";
import {useFetchMsisdnsAdminQuery} from "../../store/features/MsisdnSettings/msisdnSettingsService";

interface GroupDetailsProps {
    admin: boolean;
}

const GroupDetails = ({admin = false}: GroupDetailsProps) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const {id} = useParams<{ id: string }>();
    const isNewGroup = !id;

    const [addGroup] = useAddGroupMutation();
    const [updateGroup] = useUpdateGroupMutation();

    const [errors, setErrors] = useState({
        name: '',
        users: '',
        msisdns: ''
    });

    const isMobile = useMediaQuery('(max-width: 960px)');

    const userId = useSelector(selectUserId)


    const { data: groupData, isLoading: groupLoading } = admin ? useFetchAdminGroupByIdQuery({groupId:Number(id), userId}, { skip: isNewGroup })  : useFetchGroupByIdQuery({groupId:Number(id), userId}, { skip: isNewGroup });
    const { data: usersData = [], isLoading: usersLoading } = useFetchUsersQuery();
    const { data: msisdnsData, isLoading: msisdnsLoading } = useFetchMsisdnsAdminQuery({phrase: ''});


    const isLoading = groupLoading || usersLoading || msisdnsLoading;
    const theme = useSelector((state: any) => state.themeSwitcher.theme);



    const initialFormData = isNewGroup ? {
        name: '',
        description: '',
        type: 'MULTIPLE',
        iconColor: Colors.orange,
        users: [],
        msisdns: []
    } as Group : {
        groupId: groupData?.group?.groupId,
        name: groupData?.group?.name,
        description: groupData?.group?.description,
        type: groupData?.group?.type,
        iconColor: groupData?.group?.iconColor,
        users: groupData?.users,
        msisdns: groupData?.msisdns
    } as Group;


    const [formData, setFormData] = useState(initialFormData);




    useEffect(() => {
        if (groupData && !isNewGroup) {
            const data: Group = {
                groupId: groupData?.group?.groupId,
                name: groupData?.group?.name ?? '',
                description: groupData?.group?.description,
                type: groupData?.group?.type,
                iconColor: Colors.grey,
                users: groupData?.users ?? [],
                msisdns: groupData?.msisdns ?? []
            };
            setFormData(data);
        }
    }, [groupData, isNewGroup]);


    const validateGroupName = () => {
        setErrors(prevState =>
            ({
                ...prevState,
                name: validateName(formData.name) ? '' : 'Group name is required'
            })
        );

    }
    const handleValidation = () => {
        const newErrors = {
            name: validateName(formData.name) ? '' : 'Group name is required',
            users: formData.users?.length ? '' : 'At least one user is required',
            msisdns: formData.msisdns?.length ? '' : 'At least one phone number is required'
        };

        const hasErrors = Object.values(newErrors).some(error => error !== '');
        setErrors(newErrors);
        if(newErrors.users || newErrors.msisdns
        ) {
            dispatch(showToast({title:'Cannot create a new group.' , description:'Please add at least one user and one phone number.', type: 'error' }))
        }
        return !hasErrors;
    };

    const selectedUserIds = formData.users?.map(user => user.userId);
    const selectedMsisdnIds = formData.msisdns?.map(msisdn => msisdn.msisdnId);


    const usersList: selectorItem[] = usersData?.filter(user => !selectedUserIds?.includes(user.userId)).map((user: User) => {
        return {
            key: user.userId.toString(),
            label: user.firstName + ' ' + user.lastName,
            value: user.userId.toString(),
            prefixElement: <div className='ml-2 flex items-center'><Avatar size="md" type='initials'
                                                                           color={user.iconColor ?? Colors.auto}
                                                                           name={user.firstName + ' ' + user.lastName}/>
            </div>

        }
    });
    const selectedUsers: selectorItem[] =  formData.users?.map((user: User) => {
        return {
            key: user.userId.toString(),
            label: user.firstName + ' ' + user.lastName,
            value: user.userId.toString(),
            prefixElement: <div className='ml-2'><Avatar size="md" type='initials'
                                                         color={user.iconColor ?? Colors.auto}
                                                         name={user.firstName + ' ' + user.lastName}/>
            </div>
        }
    });

    const msisdnsList: selectorItem[] = msisdnsData ?  msisdnsData.data.filter(msisdn => !selectedMsisdnIds?.includes(msisdn.msisdnId) && msisdn.assigmentScope != 'PRIVATE' ).map((msisdn: Msisdn) => {
        return {
            key: msisdn.msisdnId.toString(),
            label: msisdn.label,
            value: msisdn.msisdnId.toString(),
            prefixElement: <div className='text-3xl ml-2'><Avatar icon={msisdn.icon} size="sm" type='icon' color={msisdn.iconColor}
            name=''/></div>
        }
    }) : [];

    const selectedMsisdns: selectorItem[] = msisdnsData ?  msisdnsData?.data.filter(msisdn => selectedMsisdnIds?.includes(msisdn.msisdnId)).map((msisdn: Msisdn) => {
        return {
            key: msisdn.msisdnId.toString(),
            label: msisdn.label,
            value: msisdn.msisdnId.toString(),
            prefixElement: <div className='text-3xl'>
            <Avatar icon={msisdn.icon} size="sm" type='icon' color={msisdn.iconColor} name=''/>
            </div>
        }
    }) : [];


    const [users, setUsers] = useState(selectedUsers ?? []);
    const [phoneNumbers, setPhoneNumber] = useState(selectedMsisdns ?? []);

    const handleMenageUsersChange = (res: boolean) => {
        if (!res) {
            setUsers([...selectedUsers]);
            return;
        }

        const matchedUsers = usersData.filter(userData => {
            return users.some(user => Number(user.value) === userData.userId);
        });


        setFormData(prevFormData => {
            return {
                ...prevFormData,
                users: matchedUsers,
            };
        });


        if (matchedUsers.length > 0 && errors.users) {
            setErrors(prevState => ({
                ...prevState,
                users: '',
            }));
        }
    };

    const handleMenagePhoneNumberChange = (res: boolean) => {
        if (!res) {
            setPhoneNumber([...selectedMsisdns]);
            return;
        }
        if(!msisdnsData) return;
        const matchedNumbers = msisdnsData.data.filter((msisdnsData: Msisdn )=> {
            return phoneNumbers.some(msisdn => Number(msisdn.value) === msisdnsData.msisdnId);
        });
        const data: Group = {
            ...formData,
            msisdns: matchedNumbers,
        };
        setFormData(data);

        if (matchedNumbers.length > 0 && errors.msisdns) {
            setErrors(prevState => ({
                ...prevState,
                msisdns: '',
            }));
        }
    };


    // const handleDataChange = (newData: any) => {
    //     setUsers(newData);
    // };

    const manageUsersTrigger = <Button
        className={!formData.users?.length ? 'w-[215px] mr-6  h-[50px]' : 'mr-6 w-[160px] h-[50px]'}
        size={formData.users?.length ? 'small' : 'default'}
        
        buttonType={'secondary'} label={formData.users?.length ? 'Manage access' : 'Add users'}></Button>

    const managePhoneNumbersTrigger = <Button className={'w-[250px] h-[50px] mr-6'}
                                              size={'small'}
                                              buttonType={'secondary'}
                                              label={formData.users?.length ? 'Manage phone numbers' : 'Add phone number'}></Button>

    const addUserModal = <AddUserNumberModal type={'USERS'} users={usersList} data={selectedUsers} setData={setUsers}/>;
    const addPhoneNumberModal = <AddUserNumberModal type={'PHONE_NUMBER'} data={selectedMsisdns} msisdns={msisdnsList}
                                                    setData={setPhoneNumber}/>;
    const handleSubmit = async () => {
        if (!handleValidation()) {

            return;
        }
        if (isNewGroup) {
            await addGroup({newGroup: formData});

        } else {
            await updateGroup(formData);
        }
       navigate('/admin/groups');
    };

    if (isLoading) {
        return (
            <div
                className={`flex w-full  ${isMobile ? ' px-4' : ''}`}>
                <Loader/>
            </div>


        );
    }
    return (
        <div className='w-full'>
            <div className="">
                <div className={` ${isMobile ? ' px-4' : ''}`}>

                    <div>

                        {!isNewGroup &&
                            <div className=" justify-start items-center gap-3 flex py-5 px-8 border-b border-black">
                                <Avatar size={"xxl"} type='icon' icon={'group'} color={'darkGrey'}/>
                                <div className="flex-col justify-start items-center gap-2 flex">
                                    <div
                                        className=" text-center  text-2xl font-semibold  leading-7">{formData?.name}</div>
                                </div>
                            </div>
                        }

                    </div>

                    <div
                        className={` w-full overflow-y-scroll`}>
                        <div className={`flex  border-b  ${isMobile ? ' mt-6' : ' '}` + clsxMerge(borderStyles({theme}))}>
                            <div className=" border-r border-grey-75 px-8 py-5 min-w-[345px]">
                                <div className='font-semibold text-lg '>
                                General information
                                </div>
                                {admin && <div className='text-grey-500 text-sm '>
                                Update name and general information.
                                </div>
                                }
                            </div>
                            <div className='px-6 py-4 w-full'>
                                            <div className={errors.name && ' pb-7'}>
                                            </div>
                                            <ChInput className='py-3 min-w-full ' value={formData?.name} label='Name'
                                                     error={errors.name}
                                                     placeholder="Enter group name"
                                                     disabled={!admin}
                                                     callback={(val: string) => {
                                                         validateGroupName();
                                                         setFormData(
                                                             {
                                                                 ...formData,
                                                                 name: val,

                                                             })
                                                     }}>
                                            </ChInput>
                                        </div>
                         </div>
                         <div className={`flex  border-b  ${isMobile ? ' mt-6' : ' '}` + clsxMerge(borderStyles({theme}))}>
                            <div className=" border-r border-grey-75 px-8 py-5 min-w-[345px]">
                                <div className='font-semibold text-lg '>
                                Users
                                </div>
                                {admin && <div className='text-grey-500 text-sm mb-5'>
                                Assign members to groups for better team organization.
                                </div>
                                }
                                {admin && Boolean(formData.users?.length) && <div className=' flex '>
                                    <ChModal title='Add team member' callback={handleMenageUsersChange}
                                            trigger={manageUsersTrigger} content={addUserModal}
                                            secondaryLabel='Cancel'
                                            primaryLabel='Confirm'/>
                                </div>}
                            </div>
                            <div className="w-full">
                            {admin && Boolean(!formData.users?.length) && <div className='p-6 flex justify-center'>
                                    <ChModal title='Add team member' callback={handleMenageUsersChange}
                                            trigger={manageUsersTrigger} content={addUserModal}
                                            secondaryLabel='Cancel'
                                            primaryLabel='Confirm'/>
                                </div>}
                            {Boolean(formData?.users?.length > 0) &&
                            <div>
                            <div className={`px-8 py-3 border-y w-full text-xs font-semibold border-grey-75 `}>
                                        Users
                                    </div>
                            <table className={`w-full `}>


                                <tbody>

                                    {formData.users?.map((user: User) => (
                                        <tr key={user.userId} className={`border-b border-grey-75 w-full` } >

                                            <td className='flex gap-3  items-center pl-4 py-4'>
                                                <Avatar size="md" type='initials'
                                                        color={user.iconColor ?? Colors.auto}
                                                        name={user.firstName + ' ' + user.lastName}/>
                                                {user.firstName + ' ' + user.lastName}
                                            </td>
                                        </tr>))

                                }
                                </tbody>
                                </table>
                                </div>
                                }
                            </div>
                         </div>

                         <div className={`flex  border-b  ${isMobile ? ' mt-6' : ' '}` + clsxMerge(borderStyles({theme}))}>
                            <div className=" border-r border-grey-75 px-8 py-5 w-[345px] min-w-[345px]">
                                <div className='font-semibold text-lg '>
                                Phone numbers
                                </div>
                                {admin && <div className='text-grey-500 text-sm mb-5'>
                                Assign a phone number to this group so it can make and receive calls.
                                </div> }
                                {admin && Boolean(formData.msisdns?.length) &&
                                        <div className='flex justify-center'>
                                            <ChModal title={'Add phone number'}
                                                     callback={handleMenagePhoneNumberChange}
                                                     trigger={managePhoneNumbersTrigger}
                                                     content={addPhoneNumberModal}
                                                     secondaryLabel='Cancel'
                                                     primaryLabel='Confirm'/>
                                        </div>}
                            </div>
                            <div className="w-full">
                            {admin && Boolean(!formData.msisdns?.length) &&
                                        <div className='p-6 flex justify-center'>
                                            <ChModal title={'Add phone number'}
                                                     callback={handleMenagePhoneNumberChange}
                                                     trigger={managePhoneNumbersTrigger}
                                                     content={addPhoneNumberModal}
                                                     secondaryLabel='Cancel'
                                                     primaryLabel='Confirm'/>
                                        </div>}
                            {Boolean(formData.msisdns?.length > 0) &&
                            <>
                            <div className={`px-8 py-3 border-y w-full text-xs font-semibold border-grey-75 `}>
                            Phone numbers
                                    </div>
                            <table className={`w-full `}>
                                <tbody>


                                {Boolean(formData.msisdns?.length) &&
                                        formData.msisdns?.map((number) => (
                                            <tr key={number.msisdnId} className={`border-b border-grey-75` }>
                                                <td className='flex gap-3  items-center pl-4 py-4'>
                                                    <div className='text-3xl'>
                                                    <Avatar icon={number.icon} size="sm" type='icon' color={number.iconColor} name=''/></div>
                                                    {number.label}
                                                </td>

                                            </tr>
                                        ))
                                    }
                                </tbody>
                                </table>
                                </>
                                }
                            </div>
                         </div>
                    </div>


                </div>
                {admin && 
                <div className={`${isMobile ? ' px-4' : ''}`}>
                <div className={`m-8 pb-8 flex  ${isNewGroup ? 'justify-end' : ' justify-between'}`}>

                    {
                        !isNewGroup && <DeleteGroupModal/>

                    }
                    <div className='flex gap-4'>
                        <Link to={'/groups'}>
                            <Button size={'large'} label='Cancel' buttonType='secondary'/>
                        </Link>
                        <Button disabled={isLoading} onClick={handleSubmit} size={'large'} label={isNewGroup? 'Create new group' : 'Done' }
                                buttonType='primary'/>
                    </div>
                </div>
            </div>
                }
                
            </div>
        </div>
    );
}
export default GroupDetails;
