import {Avatar, Button} from "ch3-ui-lib";
import {hexToEmoji} from "../Emoji/emoji";
import {formattedTime} from "../../utilis/timeUtilis";
import {CallStateEnum} from "./Dialer";
import {isPossiblePhoneNumber} from "libphonenumber-js";
import {useEffect, useState} from "react";
import {getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";

import {useSelector} from "react-redux";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import { bgStyles, borderStyles, textStyles } from "./helpers.tsx";

import {setVolume, toggleHold, toggleMuted} from './services/sipService'
import {useAppDispatch} from "../../store/store";

import {getShowKeypad, setShowKeypad} from "../../store/features/calls/dialerSlice";

interface CallButtonProps {
    icon: string;
    label: string;
    onClick: () => void;
    active?: boolean;

}



export interface CallProps {
    callState: CallStateEnum;
    timer: number;
    isIncomingCall: boolean;
    phoneNumber: string;
    callFrom: string;
    isDialerHidden: boolean;
}
const CallButton = ({icon, onClick, active}: CallButtonProps) => (
    <Button iconClassName='text-2xl w-[24px] h-[24px]' className='p-[10px] w-11 h-11' leftIcon={icon} buttonType={active ? 'primary' : 'secondary'} active={active} onClick={() => onClick()}/>
);

const Call = ({ callState, timer, isIncomingCall, callFrom, isDialerHidden }: CallProps) => {
    const [contact, setContact] = useState<string | undefined>(undefined);
    const [showVolume, setShowVolume] = useState<boolean>(false);
    const [isPhoneNumber, setIsPhoneNumber] = useState<boolean>(false);

    const theme = useSelector((state: any) => state.themeSwitcher.theme);

    const [activeButton, setActiveButton] = useState<string[]>([]);




    const dispatch = useAppDispatch();
    useEffect(()=> {
            setActiveButton([]);
        },[callState]

    )
    const showKeypad = useSelector(getShowKeypad);

    const callButtons: CallButtonProps[] = [
        { icon: "dialpad", label: "keypad", onClick: () => handleButtonClick("keypad") },
        { icon: "mic_off", label: "mute", onClick: () => handleButtonClick("mute") },
        { icon: "volume_up", label: "speaker", onClick: () => handleButtonClick("speaker") },
        { icon: "pause", label: "hold", onClick: () => handleButtonClick("hold")},
    ];

    const handleButtonClick = (label: string) => {
        if (label !== "keypad") {
            setActiveButton((prevState) => {
                if (prevState.includes(label)) {
                    return prevState.filter((button) => button !== label);
                }
                return [...prevState, label];
            });
        }
        if (label === "mute") toggleMuted();
        if (label === "speaker") setShowVolume((prevState) => !prevState);
        if (label === "hold") toggleHold();
        if (label === "keypad") {
           dispatch(setShowKeypad(!showKeypad));
        }
    };

    const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVolume(Number(e.target.value));
    };



    useEffect(() => {
        if (callFrom && (isPossiblePhoneNumber(callFrom) || isPossiblePhoneNumber("+" + callFrom))) {
            const formattedPhoneNumber = getFormatedPhoneNumber( callFrom.startsWith('+') ? callFrom.slice(1) : callFrom , false);
            setContact(formattedPhoneNumber);
            setIsPhoneNumber(true);
        } else {
            console.log(callFrom, 'callFrom')
            if (callFrom == 'unavailable') {
                setContact('Unknown');
                setIsPhoneNumber(false);
                return
            }
            setContact(callFrom);
            setIsPhoneNumber(false);
        }
    }, [callFrom]);

    return (
        <div>

            {(!isDialerHidden && callState == "CONNECTING" && !showKeypad ) && (
                <div className={`flex flex-col max-w-xs ` + clsxMerge(borderStyles({theme}))}>
                    <main className="flex flex-col items-center px-7 w-full font-semibold">
                        <Avatar size={"xxl"} type={(!isPhoneNumber && contact === "Unknown") ? "icon" : (contact ?  'initials' : 'default')} icon={'warning'} color={"auto"} name={contact} className="pb-4 px-[130px] pt-7" />
                        <div className="text-base leading-6 text-neutral-700">Calling...</div>
                        <h1 className={`flex justify-center self-stretch mt-3 text-2.5xl text-black text-center ` + clsxMerge(textStyles({theme}))}>{contact}</h1>
                    </main>
                </div>
            )}
            {(callState === "IN_CALL" && !showKeypad) && (
                <div className={`flex flex-col max-w-xs border-t ` + clsxMerge(borderStyles({theme}))}>
                    <main className="flex flex-col items-center px-8 pb-12 w-full font-semibold ">
                        <Avatar size={"xxl"}type={(!isPhoneNumber && contact === "Unknown") ? "icon" : (contact ?  'initials' : 'default')} icon={'warning'} color={"auto"}  name={contact} className="pb-4 px-[130px] pt-7" />
                        <div className="mt-6 text-base leading-6 text-neutral-700"></div>
                        <h1 className={`text-2.5xl  text-center ` + clsxMerge(textStyles({theme}))}>{contact}</h1>

                        <div className="text-[16px]">
                            <div className="flex justify-center gap-4 mt-4 items-center">
                                {hexToEmoji("1F551")}
                                {formattedTime(timer)}
                            </div>
                        </div>
                    </main>

                </div>

            )}
            {
                ((callState === "IN_CALL" || callState === "CONNECTING") && !showKeypad ) && (
                    <section
                        className="flex flex-col flex-wrap justify-center content-start mx-auto self-center px-8 mt-10 mb-6 w-full max-w-[277px] ">
                        {(showVolume) && (
                            <input
                                className="relative left-[140px] bottom-5 timeline-input transform volume w-24 h-1 bg-black focus:bg-gradient-to-r focus:from-black focus:to-black appearance-none"
                                type="range"
                                min="0"
                                max="1"
                                step="0.1"
                                defaultValue={1}
                                onChange={(event) => handleVolumeChange(event)}
                                id="volumeSlider"
                            />
                        )}
                        <div className="flex justify-center gap-[34px]">
                            {callButtons.slice(0, 3).map((button) => (
                                <div key={button.label}>
                                    {activeButton.includes(button.label)}
                                    <CallButton  icon={button.icon} label={button.label} onClick={button.onClick}
                                                 active={activeButton.includes(button.label)}/>
                                    <div className="mt-2 text-xs  text-center">{button.label}</div>

                                </div>
                            ))}

                        </div>
                        <div className="flex justify-center gap-[34px] mt-5">
                            {callButtons.slice(3).map((button) => (
                                <div>
                                    <CallButton key={button.label} onClick={button.onClick} icon={button.icon}
                                                label={button.label} active={activeButton.includes(button.label)}/>
                                    <div className='mt-2 text-xs  text-center'>{button.label}</div>
                                </div>
                            ))}
                        </div>
                    </section>
                )
            }
            {!isDialerHidden && callState === "IDLE" && isIncomingCall && (
                <div className={`flex flex-col pb-8 max-w-xs border-x border-t ` + clsxMerge(borderStyles({theme}), bgStyles({theme}))}>
                    <main className="flex flex-col items-center px-8 w-full font-semibold">
                        <Avatar size={"xxl"} type={(!isPhoneNumber && contact === "Unknown") ? "icon" : (contact ?  'initials' : 'default')} icon='warning' color={"auto"} name={contact} className="pb-4 px-[130px] pt-7" />
                        <div className="mt-6 text-base leading-6 text-neutral-700"></div>

                        <div className="font-base font-normal">Call from</div>
                        <h1 className={`mt-3 text-2.5xl text-black text-center ` + clsxMerge(textStyles({theme}))}>{contact}</h1>
                    </main>
                </div>
            )}
        </div>
    );
};

export default Call;
