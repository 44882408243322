import {useSelector} from "react-redux";
import {UserRow} from "./UserRow";
import {useState} from "react";
import {clsxMerge} from "../../../utilis/classNameUtils";
import {borderStyles} from "../../Groups/helpers";
import {Button, ChCheckbox, ChInput, Loader} from "ch3-ui-lib";
import {boxStyles} from "../../Contacts/helpers";
import useMediaQuery from "../../../utilis/screenWidthUtils";
import {
    GoogleIntegrationResponse,
    GoogleUser,
    useImportGoogleContactsMutation
} from "../../../store/features/integrations/integrationsService";
import {useAppDispatch} from "../../../store/store";
import {ClosedDialerMargin, OpenDialerMargin} from "../../../utilis/dialerWidthUtils";
import {showToast} from "../../../store/features/Notifications/notificationsSlice";



interface UserListProps {
    integration: GoogleIntegrationResponse;

}

export const UserList = ({integration}:UserListProps) => {

    const [selected, setSelected] = useState<GoogleUser[]>([]);
    const [userList, setUserList] = useState<GoogleUser[]>(integration.googleResponse.users);

    const dispatch = useAppDispatch();
    const isDialerHidden = useSelector((state: any) => state.dialer.isDialerHidden);

    const [importGoogleContacts] = useImportGoogleContactsMutation();
    const theme = useSelector((state: any) => state.themeSwitcher.theme);
    const isMobile = useMediaQuery('(max-width: 960px)');


    const handleSelect = (user: any) => {
        if (selected.includes(user)) {
            setSelected(selected.filter((u: any) => u !== user));
        } else {
            setSelected([...selected, user]);
        }
    }
    const onSelectAll = () => {
        if (selected.length === integration.googleResponse.users.length) {
            setSelected([]);
        } else {
            setSelected(integration.googleResponse.users);
        }

    }
    const isSelectedAll = () => {
        if(selected.length) {
           return  selected.length === integration.googleResponse.users.length ? true : 'indeterminate';
        }
        return;
    }
    const handleSubmit = async () => {
        try {
            const payload = {
                integration: integration.integrationId,
                users: selected,
            };
            await importGoogleContacts(payload);
            dispatch(
                showToast({
                    title: "Success",
                    description: "Users successfully imported.",
                    type: "success",
                })
            );
        } catch (error) {
            dispatch(
                showToast({
                    title: "Error",
                    description: "Failed to import users.",
                    type: "error",
                })
            );
        }
    };

    const handleDataChange = (value: string) => {
        const filtered = integration.googleResponse.users.filter((user: any) => {
            return user.firstName.toLowerCase().includes(value.toLowerCase()) || user.lastName.toLowerCase().includes(value.toLowerCase()) || user.email.toLowerCase().includes(value.toLowerCase());
        });
        setUserList(filtered);

    }
    if (!integration.googleResponse.users) {
        return <div className={`flex w-full  ${isMobile ? ' px-4' : isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
            <Loader/>
        </div>
    }
    return (
        <>
            <div className='flex justify-end w-full mr-8'>
                <ChInput placeholder={'Search'} className={`${isMobile ? ' ' : ' min-w-[375px]'}`}  prefixIcon={'search'} callback={handleDataChange}/>

            </div>
            <div className='w-full ml-8 mr-4'>
                <div className={` flex flex-col border  ${isMobile ? ' mt-6' : ' mr-4 mt-8 '}` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                    <thead className={'border-b ' + clsxMerge(borderStyles({theme}))}>
                    <div className={'font-semibold text-lg mb-0 py-5 pr-6 pl-6 border-b ' + clsxMerge(borderStyles({theme}))}>
                        List of users
                    </div>
                    <tr className={`border-b ` + clsxMerge(borderStyles({theme}))} >
                        <div className={'font-semibold p-3 '}>
                            <ChCheckbox value={''} checked={isSelectedAll()} label={'Select all'} callback={() => {onSelectAll()}} />
                        </div>
                    </tr>
                    </thead>
                    <div className='h-[calc(100vh-600px)] overflow-y-auto '>
                        {
                            userList.map((user: any) => {
                                return (
                                    <UserRow user={user} handleSelect={handleSelect} isSelected={selected.some((u) => u.email === user.email)} />
                                );
                            })
                        }
                    </div>


                </div>
            </div>

    <div className='flex justify-end w-full py-4 px-8'>
        <Button onClick={()=> {
            handleSubmit();
        }} label={'Download Contacts'}  />
    </div>
    </>
    );

}
